<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Import History"/>
        <v-spacer></v-spacer>
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
        <v-toolbar class="search-tools pa-0 mb-4" flat>
          <v-col class="d-flex" cols="10" sm="10">
                <v-autocomplete
                  label="Select import file"
                  :items="instoreImportComplete"
                  :item-text="'filename'"
                  :item-value="'id'"
                  v-model="instore_file"
                  no-data-text="Select.."
                ></v-autocomplete>
          </v-col>
          <v-btn class="mx-2" depressed dark small color="primary" @click="loadHistoryImport();">
            Load
          </v-btn>
        </v-toolbar>
        <v-col class="d-flex" cols="4" sm="4" offset="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-data-table 
            :headers="headers"
            :items="instore_datas" 
            :options.sync="options"
            item-key="Id"
            :search="search"
            :loading="loadingDataTable"
            :footer-props="{
              'items-per-page-options': [25, 100, 500]
            }"
            class="text-small"
            multi-sort
            no-data-text="There is no data"
        >

          <template #item.updatedAt="{value}">
                {{ value | datetime}}
          </template>

          <template v-slot:[`item.zendesk`]="{item}">
            {{ item.ticket_token ? item.ticket_token : '-' }}
          </template>

          <template v-slot:[`item.amount_topup`]="{item}">
            {{ item.match_order_no["view_lastest_order.driver_delivery_fee"] ? item.match_order_no["view_lastest_order.driver_delivery_fee"] : '-' }}
          </template>

          <template v-slot:[`item.status`]="{item}">
            {{ item.record_case != 11 ? mapStatus(item.record_case) : item.ticket_token ? 'รอการ approve' : 'รอการ approve'}}
          </template>

          <template v-slot:[`item.driver`]="{item}">
            {{ item.match_employee ? item.match_employee.name_th : item.file_data.driver }}
          </template>

        </v-data-table>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';

export default {
  name: 'InstoreDriver',
  components: {
      pageHeading
  },
  data: () => ({
    search: '',
    dialog: false,
    instore_file : null,
    instore_datas :[],
    form_valid : false,
    options: { 
        page: 1,
        itemsPerPage: 25,
        sortBy: ['id'],
        sortDesc: [true],
        multiSort: true,
        mustSort: true,
    },
  }),
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    ...mapActions(['genCan','getInstoreImportOrderHistory','getImportInstoreComplete']),
    
    async loadHistoryImport() {
      console.log(this.instore_file)
        let is_history = true
        const getDataStoreFile = await this.getInstoreImportOrderHistory(this.instore_file,is_history);

        for(let item in getDataStoreFile.import_content){
          let ordertime = ''

          if(getDataStoreFile.import_content[item].file_data['order time']){
            let order_hour = Math.floor(Math.round(getDataStoreFile.import_content[item].file_data['order time'] * 1440) / 60) + ''
            let order_time = (Math.round(getDataStoreFile.import_content[item].file_data['order time'] * 1440) % 60) + ''
            ordertime = getDataStoreFile.import_content[item].file_data.date + ' ' + order_hour + ':' + order_time
          }

          getDataStoreFile.import_content[item].file_data['order time'] = ordertime
        }

        this.instore_datas = getDataStoreFile.import_content.map( d => {
            return {
              ...d,
              driver_phone: d.file_data["edit phone driver"] ? d.file_data["edit phone driver"] : d.file_data["phone driver"],
            }
          });

        const result = getDataStoreFile.import_content.find(({ last_employee_id,match_employee_id }) => last_employee_id == null || match_employee_id != last_employee_id);
        console.log(result)

    },

    mapStatus(record_id) {
      let status = '';
      switch (record_id) {
        case 1: status = 'พร้อมนำเข้าข้อมูล'
          break;
        case 2: status = 'Rider มีข้อมูลใน Order แล้ว'
          break;
        case 3: status = 'มีข้อมูล Rider ใน Order แล้ว แต่ไม่ตรงกับในไฟล์ที่ Import'
          break;
        case 4: status = 'ใน DM ไม่มีข้อมูล Rider และไม่สามารถหา Rider จากข้อมูลใน ไฟล์ Import ได้'
          break;
        case 5: status = 'ใน DM มีข้อมูล Rider แต่ไม่สามารถหา Rider จากข้อมูลในไฟล์ Import ได้'
          break;
        case 6: status = 'ไม่พบข้อมูล Order'
          break;
        case 7: status = 'ไม่พบข้อมูล Order และ Rider'
          break;
        case 8: status = 'ข้อมูลจากไฟล์มีไม่ครบ'
          break;
        case 9: status = 'Order ทำจ่ายเงินไปแล้ว'
          break;
        case 10: status = 'ข้อมูลถูกแก้ไข'
          break;
        case 11: status = 'รอการ approve'
          break;
        case -1: status = 'กำลังประมวลผล'
          break;
        default: status = '-'
          break;
      }

      return status;
    },

  },
  computed: {
    ...mapGetters(['canDo','loadingDataTable','instoreImportComplete']),
    dataLisings() { 
        var res = [];
        return  res;
    },
    headers(){
      return [
        {text: "Id", value: 'file_data.id',align: 'center',width: '150px',sortable:false},
        {text: "Store Name", value: 'file_data.store name', align: 'center',width: '120px'},
        {text: "Order Time", value: 'file_data.order time', align: 'left',width: '120px',sortable: false},
        {text: "Customer Phone", value: 'file_data.phone customer', align: 'left',width: '150px'},
        {text: "Driver", value: 'driver', align: 'center',width: '120px'},
        {text: "Rider Phone", value: 'driver_phone', align: 'left',width: '150px'},
        {text: "Order No.", value: 'match_order_no.order_no', align: 'center',width: '150px',sortable:false},
        {text: "Rider No ใหม่", value: 'match_employee.employee_no', align: 'center',width: '120px',sortable:false},
        {text: "Rider No เดิม", value: 'last_employee.employee_no', align: 'center',width: '120px',sortable:false},
        {text: "Updated Date", value: 'updatedAt', align: 'center',width: '150px'},
        {text: "Status", value: 'status', align: 'center',width: '150px'},
        {text: "Amount Topup", value: 'amount_topup', align: 'center',width: '150px'},
        {text: "Zendesk Ticket", value: 'zendesk', align: 'center',width: '150px'},
      ]
    },
  },
  mounted() {
    this.genCan();
    this.getImportInstoreComplete();
  }
};
</script>
<style scoped>
.v-data-table {
  max-width: 100%
}
</style>